import React from 'react';
import { Link } from 'react-router-dom';
import { http } from 'data-graphql';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Header, Button, Menu } from 'components';
import moment from 'moment';

const statusLabels = {
  'awaiting_processing': 'Awaiting Processing',
  'received': 'Received',
  'on_hold': 'On Hold',
  'complete': 'Complete',
  'cancelled': 'Cancelled',
}

const Wrapper = styled.div`
  .table {
    display: block;
    max-width: 1300px;
    margin: .5em auto;
    padding: 15px 20px;
  }

  .table table {
    width: 100%;
    background: white;
    table-spacing: 0;
    border-collapse: collapse;
  }

  .table thead tr {
    background: #6e1406;
    color: white;
  }

  tr td {
    margin: 0;
    border: none;
    text-align: center;
  }

  .table tbody td {
    border-bottom: 1px solid #CCC;
  }

  .table th {
    padding: .4em;
    cursor: pointer;
  }

  .table tbody td {
    padding: .4em;
  }

  .table td:first-child {
    max-width: 50px;
    text-align: center;
  }

  .table td:nth-child(2) {
    max-width: 250px;
    padding-left: 1em;
  }

  .table input {
    border: 1px solid #AAA;
  }

  .trackEditInstructions {
    font-size: 1.1rem;
    color: #6e1406;
    padding: 2em;
    border: 3px solid #6e1406;
    background: white;
    font-weight: bold;
    text-align: center;
    max-width: 800px;
    margin: 4em auto 0;
  }
`

const printDate = date => {
  const d = new Date(date);
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
}

class SortIndicator extends React.Component {
  render() {
    const { sortBy, sortDirection } = this.props;
    if (sortBy === this.props.field) {
      return <span>{sortDirection === 'asc' ? ' \\/' : ' /\\'}</span>
    }
    return null;
  }
}

export default class OrgEdits extends React.Component {
  constructor() {
    super();
    this.state = {
      edits: [],
      sortBy: null,
      sortDirection: 'asc',
      menu: 'Current Shows',
    };
  }

  async componentDidMount() {
    await http().get('/editing/list').then(response => {
      if (!response.success) {
        return toast.error('Failed to retrieve edits');
      }
      console.log(response.items)
      this.setState({ edits: response.items });
    }).catch(error => {
      console.log(error)
      toast.error(error.message);
    })
  }

  sortBy = field => {
    let sortDirection = this.state.sortDirection;
    if (this.state.sortBy === field) {
      sortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      sortDirection = 'asc';
    }

    const edits = this.state.edits.sort((_a, _b) => {
      let a = _a;
      let b = _b;

      // if the field is a date, convert it to a date object
      if (field === 'created_at' || field === 'due_at') {
        a[field] = new Date(a[field]).valueOf();
        b[field] = new Date(b[field]).valueOf();
      }


      // Sort considering direction
      if (a[field] < b[field]) return sortDirection === 'asc' ? -1 : 1
      if (a[field] > b[field]) return sortDirection === 'asc' ? 1 : -1
      return 0

    });
    this.setState({ edits, sortBy: field, sortDirection });
  }

  render() {
    return <Wrapper>
      <Header>
        <Header.Head bold huge>
          <div>
            Edit Requests
          </div>
        </Header.Head>
        <Menu>
          <button
            value="Current Shows"
            onClick={() => this.setState({ menu: 'Current Shows' })}
            active={this.state.menu === 'Current Shows'}
          >
            Current Shows
                  </button>
          <button
            value="Expired Shows"
            onClick={() => this.setState({ menu: 'Expired Shows' })}
            active={this.state.menu === 'Expired Shows'}
          >
            Expired Shows
                  </button>
        </Menu>
      </Header>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th onClick={() => this.sortBy('show_name')}>
                Show
                <SortIndicator
                  field='show_name'
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection} />
              </th>
              <th onClick={() => this.sortBy('created_at')}>
                Submission date
                <SortIndicator
                  field='created_at'
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection} />
              </th>
              <th onClick={() => this.sortBy('due_at')}>
                Due Date
                <SortIndicator
                  field='due_at'
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection} />
              </th>
              <th onClick={() => this.sortBy('turnaround_time')}>
                Turnaround
                <SortIndicator
                  field='turnaround_time'
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection} />
              </th>
              <th onClick={() => this.sortBy('status')}>
                Status
                <SortIndicator
                  field='status'
                  sortBy={this.state.sortBy}
                  sortDirection={this.state.sortDirection} />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.edits
              .filter(edit => {
                const isCurrent = moment().isBefore(edit.expiration);
                if (this.state.menu === 'Current Shows') return isCurrent
                return !isCurrent
              })
              .map(edit => {
                let className = `status_${edit.status}`
                if (new Date(edit.due_at).valueOf() < new Date().valueOf()) {
                  className += ' overdue'
                }

                const isDraft = Boolean(edit.draft);

                return <tr key={edit.id} className={className}>
                  <td>{edit.show_name}</td>
                  <td>{isDraft ? '' : printDate(edit.created_at)}</td>
                  <td>{isDraft ? '' : printDate(edit.due_at)}</td>
                  <td>{isDraft ? '' : `${edit.turnaround_time} days`} </td>
                  <td>{isDraft ? 'NOT SUBMITTED' : statusLabels[edit.status] || 'Unknown'}</td>
                  <td>
                    <Link to={`/org/edits/${edit.id}`}>
                      <Button>View Details</Button>
                    </Link>
                  </td>
                </tr>
              })}
          </tbody>
        </table>
        <div className="trackEditInstructions">
          To submit a new edit request, please click the Shows tab, click on your show's title, and then click on the Editing tab near the top of the page.
        </div>
      </div>
    </Wrapper>;
  }
}
