import React from 'react';
import { Button, Sidebar, Icon, Spacer } from 'components';
import { withRouter } from 'react-router-dom';
import COLORS from './colors';
import styled from 'styled-components';
import LogoSrc from 'assets/logo.svg';
import mtiLogo from 'assets/mtiLogoBottomAligned.png';
import http from 'data-graphql/http';
import CheeseburgerMenu from 'cheeseburger-menu';
import HamburgerMenu from 'react-hamburger-menu';
import { isMedia } from 'helpers';
import { isMTI } from '../helpers';

const Logo = styled.img`
  width: 100%;
  padding: 10px 20px 0 20px;
  margin-bottom: 24px;
`;
const MenuIcon = styled(Icon)`
  font-size: 26px;
  padding-right: 15px;
`;
const SidebarContent = styled(Sidebar.Content)`
  display: flex;
  flex-direction: column;
  background: ${COLORS.lightGrey};
`;

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

class SidebarWrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      user: null,
      expanded: false,
      expand: {},
      isHovering: false,
      window: '',
      menuOpen: false
    };
  }

  logout() {
    window.localStorage.removeItem('token');
    this.props.history.push('/');
  }

  window = () => {
    let window = isMedia();
    this.setState({ window });
  };

  UNSAFE_componentWillUnmount() {
    window.removeEventListener('resize', this.throttledHandleWindowResize);
  }

  componentDidMount() {
    window.addEventListener('resize', this.window);
    this.window();

    http(false)
      .get('/me')
      .then(user => {
        this.setState({ user: user.result });
      })
      .catch(() => {
        this.logout.apply(this);
      });
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen
    }));
  };

  render() {
    const path = this.props.location.pathname;

    const { type } = this.props;
    const { user } = this.state;

    if (!this.state.user) return null;

    return (
      <>
        {this.state.window === 'desktop' ? (
          <>
            <Sidebar.Wrapper>
              <Sidebar>
                <Logo src={isMTI ? mtiLogo : LogoSrc} />
                <Profile>
                  <Icon
                    name="account-circle"
                    style={{
                      fontSize: 50,
                      borderRadius: '50%',
                      color: type === 'admin' ? COLORS.lighterYellow : 'white'
                    }}
                  />
                  <Spacer size="8px" />
                  {user.username}
                </Profile>
                <Spacer size="24px" />
                {type === 'admin' && (
                  <Sidebar.Item
                    to="/admin/admins"
                    active={path.includes('/admins') ? 1 : 0}
                  >
                    <MenuIcon name="key" />
                    Admins
                  </Sidebar.Item>
                )}

                <Sidebar.Item
                  to={`/${type}/shows`}
                  active={path.includes('/shows') ? 1 : 0}
                >
                  <MenuIcon name="music-cd" />
                  Shows
                </Sidebar.Item>
                {type === 'org' && (
                  <Sidebar.Item
                    to="/org/contacts"
                    active={path.includes('/contact') ? 1 : 0}
                  >
                    <MenuIcon name="hierarchy" />
                    Contacts
                  </Sidebar.Item>
                )}

                {type === 'admin' && (
                  <Sidebar.Item
                    to="/admin/organizations"
                    active={path.includes('/organizations') ? 1 : 0}
                  >
                    <MenuIcon name="hierarchy" />
                    Organizations
                  </Sidebar.Item>
                )}
                {type === 'admin' && (
                  <Sidebar.Item
                    to="/admin/users"
                    active={path.includes('/users') ? 1 : 0}
                  >
                    <MenuIcon name="account-group-1" />
                    Users
                  </Sidebar.Item>
                )}
                <Sidebar.Item
                  to={`/${type}/account`}
                  active={path.includes('/account') ? 1 : 0}
                >
                  <MenuIcon name="account-circle" />
                  Account
                </Sidebar.Item>
                {type === 'admin' && !isMTI && (
                  <Sidebar.Item
                    to="/admin/quotes"
                    active={path.includes('/admin/quotes') ? 1 : 0}
                  >
                    <MenuIcon name="money" />
                    Quotes
                  </Sidebar.Item>
                )}
                {type === 'admin' && (
                  <Sidebar.Item
                    to="/admin/reporting"
                    active={path.includes('/admin/reporting') ? 1 : 0}
                  >
                    <MenuIcon name="graph-bar" />
                    Reporting
                  </Sidebar.Item>
                )}
                {type === 'admin' && (
                  <Sidebar.Item
                    to="/admin/emails"
                    active={path.includes('/admin/emails') ? 1 : 0}
                  >
                    <MenuIcon name="email" />
                    Emails
                  </Sidebar.Item>
                )}
                {type === 'admin' && (
                  <Sidebar.Item
                    to="/admin/edits"
                    active={path.includes('/admin/edits') ? 1 : 0}
                  >
                    <MenuIcon name="pencil-1" />
                    Edit Requests
                  </Sidebar.Item>
                )}
                {type === 'org' && !isMTI && (
                  <Sidebar.External
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_MARKETING_URL}get-a-quote/?oid=${user.organization_id}`}
                  >
                    <MenuIcon name="email-text" />
                    Get a Quote
                  </Sidebar.External>
                )}
                {type === 'org' && (
                  <Sidebar.Item
                    to="/org/edits"
                    active={path.includes('/org/edits')}
                    onClick={this.toggleMenu}
                  >
                    <MenuIcon name="pencil-1" />
                      Edit Requests
                  </Sidebar.Item>
                )}
                {!isMTI && (
                  <Sidebar.External
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_MARKETING_URL}faqs/`}
                  >
                    <MenuIcon name="bubble-chat-question" />
                    FAQ
                  </Sidebar.External>
                )}
                {isMTI && (
                  <Sidebar.Item
                    to="/faq"
                    active={path.includes('/faq') ? 1 : 0}
                    onClick={this.toggleMenu}
                  >
                    <MenuIcon name="bubble-chat-question" />
                    FAQ
                  </Sidebar.Item>
                )}

                <Button onClick={this.logout.bind(this)} large fluid straight>
                  Logout
                </Button>
                <br />
                <br />
              </Sidebar>
              <SidebarContent>{this.props.children}</SidebarContent>
            </Sidebar.Wrapper>
          </>
        ) : (
            <>
              <div style={{ position: 'absolute', left: '24px', top: '24px' }}>
                <HamburgerMenu
                  width={30}
                  height={20}
                  isOpen={this.state.menuOpen}
                  menuClicked={this.toggleMenu}
                  color={COLORS.darkRed}
                  strokeWidth={3}
                />
              </div>
              <CheeseburgerMenu
                isOpen={this.state.menuOpen}
                closeCallback={this.toggleMenu}
              >
                <Sidebar.Wrapper>
                  <Sidebar>
                    <Logo src={isMTI ? mtiLogo : LogoSrc} />
                    <Spacer size="20px" />
                    <Profile>
                      <Icon
                        name="account-circle"
                        style={{
                          fontSize: 50,
                          borderRadius: '50%',
                          color: type === 'admin' ? COLORS.lighterYellow : 'white'
                        }}
                      />
                      <Spacer size="8px" />
                      {user.username}
                    </Profile>
                    <Spacer size="16px" />
                    {type === 'admin' && (
                      <Sidebar.Item
                        to="/admin/admins"
                        active={path.includes('/admins')}
                        onClick={this.toggleMenu}
                      >
                        <MenuIcon name="key" />
                      Admins
                      </Sidebar.Item>
                    )}

                    <Sidebar.Item
                      to={`/${type}/shows`}
                      active={path.includes('/shows')}
                      onClick={this.toggleMenu}
                    >
                      <MenuIcon name="music-cd" />
                    Shows
                  </Sidebar.Item>
                    {type === 'org' && (
                      <Sidebar.Item
                        to="/org/contacts"
                        active={path.includes('/contact')}
                        onClick={this.toggleMenu}
                      >
                        <MenuIcon name="hierarchy" />
                      Contacts
                      </Sidebar.Item>
                    )}

                    {type === 'admin' && (
                      <Sidebar.Item
                        to="/admin/organizations"
                        active={path.includes('/organizations')}
                        onClick={this.toggleMenu}
                      >
                        <MenuIcon name="hierarchy" />
                      Organizations
                      </Sidebar.Item>
                    )}
                    {type === 'admin' && (
                      <Sidebar.Item
                        to="/admin/users"
                        active={path.includes('/users')}
                        onClick={this.toggleMenu}
                      >
                        <MenuIcon name="account-group-1" />
                      Users
                      </Sidebar.Item>
                    )}
                    <Sidebar.Item
                      to={`/${type}/account`}
                      active={path.includes('/account')}
                      onClick={this.toggleMenu}
                    >
                      <MenuIcon name="account-circle" />
                    Account
                  </Sidebar.Item>
                    {type === 'admin' && !isMTI && (
                      <Sidebar.Item
                        to="/admin/quotes"
                        active={path.includes('/admin/quotes')}
                        onClick={this.toggleMenu}
                      >
                        <MenuIcon name="money" />
                      Quotes
                      </Sidebar.Item>
                    )}
                    {type === 'admin' && !isMTI && (
                      <Sidebar.Item
                        to="/admin/reporting"
                        active={path.includes('/admin/reporting')}
                        onClick={this.toggleMenu}
                      >
                        <MenuIcon name="graph-bar" />
                      Reporting
                      </Sidebar.Item>
                    )}
                    {type === 'admin' && !isMTI && (
                      <Sidebar.Item
                        to="/admin/emails"
                        active={path.includes('/admin/emails')}
                        onClick={this.toggleMenu}
                      >
                        <MenuIcon name="email" />
                      Emails
                      </Sidebar.Item>
                    )}
                    {type === 'admin' && !isMTI && (
                      <Sidebar.Item
                        to="/admin/edits"
                        active={path.includes('/admin/edits')}
                        onClick={this.toggleMenu}
                      >
                        <MenuIcon name="pencil-1" />
                      Edit Requests
                      </Sidebar.Item>
                    )}
                    {type === 'org' && !isMTI && (
                      <Sidebar.External
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${process.env.REACT_APP_MARKETING_URL}get-a-quote/?oid=${user.organization_id}`}
                      >
                        <MenuIcon name="bubble-chat-question" />
                      Get a Quote
                      </Sidebar.External>
                    )}
                    {type === 'org' && !isMTI && (
                      <Sidebar.Item
                        to="/org/edits"
                        active={path.includes('/org/edits')}
                        onClick={this.toggleMenu}
                      >
                        <MenuIcon name="pencil-1" />
                      Edit Requests
                      </Sidebar.Item>
                    )}
                    {!isMTI && (
                      <Sidebar.External
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${process.env.REACT_APP_MARKETING_URL}faqs/`}
                      >
                        <MenuIcon name="bubble-chat-question" />
                      FAQ
                      </Sidebar.External>
                    )}
                    {isMTI && (
                      <Sidebar.Item
                        to="/faq"
                        active={path.includes('/faq')}
                        onClick={this.toggleMenu}
                      >
                        <MenuIcon name="bubble-chat-question" />
                      FAQ
                      </Sidebar.Item>
                    )}
                    <Button onClick={this.logout.bind(this)} large fluid straight>
                      Logout
                  </Button>
                    <br />
                    <br />
                  </Sidebar>
                  <SidebarContent>{this.props.children}</SidebarContent>
                </Sidebar.Wrapper>
              </CheeseburgerMenu>
            </>
          )}
      </>
    );
  }
}

export default withRouter(SidebarWrapper);
