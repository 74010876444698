import React from 'react';
import { http } from 'data-graphql';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Header, FormWrapper, Text, Input, Button } from 'components';

const Wrapper = styled.div`
  .table {
    display: block;
    max-width: 1300px;
    margin: .5em auto;
    padding: 15px 20px;
  }

  .table table {
    width: 100%;
    background: white;
    table-spacing: 0;
    border-collapse: collapse;
  }

  .table thead tr {
    background: #6e1406;
    color: white;
    text-align: left;
  }

  .topInfo {
    display: flex;
    flex-wrap: wrap;
    margin: 1em auto;
    max-width: 1300px;
  }

  .topInfo > div {
    width: 50%;
    padding: 1em;
  }

  .topInfo > div > div:first-child {
    margin-bottom: .5em;
    font-size: 1.2em;
    font-weight: bold;
  }

  .topInfo .field {
    margin-bottom: .5em;
    text-align: left;
  }

  tr td {
    margin: 0;
    border: none;
    text-align: center;
  }

  .table tbody td {
    border-bottom: 1px solid #CCC;
  }

  .table tbody td:last-child {
    text-align: left;
  }

  .table th {
    padding: .4em;
  }

  .table tbody td {
    padding: .4em;
  }

  .table td:first-child {
    max-width: 50px;
    text-align: center;
  }

  .table td:nth-child(2) {
    max-width: 250px;
    padding-left: 1em;
  }

  .table input {
    border: 1px solid #AAA;
  }

  input[disabled] {
    background-color: #FFE;
  }

  .radios > div {
    margin-bottom: .5em;
  }

  .radios input {
    margin-right: .5em; 
  }

  .statusInfo {
    color: white;
    background: #6e1406;
    max-width: 300px;
    margin: 0 auto;
    padding: 12px 0 ;
  }

  .statusInfo .statusDraft {
    font-weight: bold;
    font-size: 18px;
  }

  .statusInfo > :last-child {
    font-weight: normal;
    font-size: 14px;
  }

  .editButtonHolder {
    text-align: center;
    padding: 12px 0;
    margin-bottom: 20px;
  }

  .editButtonHolder button {
    font-size: 18px;
    color: black;
    border-radius: 5px;
    padding: 8px 20px;
  }

  .editButtonHolder > div:last-child {
    color: #6e1406;
    text-align: center;
    max-width: 350px;
    margin: 12px auto;
    font-size: 14px;
  }
`

const statusLabels = {
  'awaiting_processing': 'Awaiting Processing',
  'received': 'Received',
  'on_hold': 'On Hold',
  'complete': 'Complete',
  'cancelled': 'Cancelled',
}

export default class OrgEdits extends React.Component {
  constructor() {
    super();
    this.state = {
      show: {},
      edit: {},
      booking: {},
    };
  }

  async componentDidMount() {
    await http().get(`/editing/${this.props.id}`).then(response => {
      if (!response.success) {
        return toast.error('Failed to retrieve edit');
      }
      // Sort edits by cue number
      response.edit.edits.sort((a, b) => a.cue_number.localeCompare(b.cue_number));
      console.log({ response })
      this.setState(response);
    }).catch(error => {
      console.log(error)
      toast.error(error.message);
    })
  }

  render() {
    if (!this.state.edit || !this.state.edit.edits || !this.state.booking) return null;
    return <Wrapper>
      {!this.props.hideHeader && (
        <Header>
          <Header.Head bold huge>
            Edit Request #{this.state.editN} for {this.state.show.name}
          </Header.Head>
        </Header>
      )}
      <FormWrapper>
        <div className='topInfo'>
          <div className='topInfo1'>
            <div>Contact information</div>
            <div>
              <div className="field">
                Name: <Input value={this.state.edit.name} />
              </div>
              <div className="field">
                Phone: <Input value={this.state.edit.phone} />
              </div>
              <div className="field">
                Email: <Input type="email" value={this.state.edit.email} />
              </div>
            </div>
          </div>
          <div className='topInfo2'>
            <div>Organization Information</div>
            <div>
              <div className="field">
                Organization: <Input value={this.state.org.name} disabled />
              </div>
              <div className="field">
                Opening Date: <Input value={new Date(this.state.booking.opening).toLocaleDateString()} disabled />
              </div>
              <div className="field">
                Account Number: <Input value={this.state.org.account_number} disabled />
              </div>
            </div>
          </div>
          <div className='topInfo3'>
            <div>Turnaround Time</div>
            {this.state.edit.turnaround_time === '5' && (
              <div> 5 Business-day ($60/hour) </div>
            )}

            {this.state.edit.turnaround_time === '2' && (
              <div> 2 Business-day ($120/hour) </div>
            )}
          </div>
          <div className='topInfo4'>
            <div className="statusInfo">
              <div>Status</div>
              <div>
                {this.state.edit.draft 
                  ? <div className="statusDraft">NOT SUBMITTED</div>
                  : statusLabels[this.state.edit.status] || this.state.edit.status}
              </div>
            </div>
          </div>
        </div>

        <Text large>Edits Requested</Text>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th>Cue #</th>
                <th>Track Name</th>
                <th>Edit Request</th>
              </tr>
            </thead>
            <tbody>
              {this.state.edit.edits.map(edit => {
                return <tr key={edit.id}>
                  <td>{edit.cue_number}</td>
                  <td style={{ textAlign: 'left' }}>{edit.track_name}</td>
                  <td>
                    <ul>
                      {edit.edit.filter(e => e?.length > 0).map((e, i) => <li key={i}>{e}</li>)}
                    </ul>
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        <div>
          {this.state.edit.editor_notes && (
            <div>
              <div>
                <Text large>Editor Notes</Text>
              </div>
              <div>
                <Text>{this.state.edit.editor_notes}</Text>
              </div>
            </div>
          )}
        </div>
      </FormWrapper>
      {this.state.edit.status === 'awaiting_processing' && (
        <div className="editButtonHolder">
          <div>
            <Button onClick={() => {
              window.location.href = `/org/edits/${this.state.booking.id}/edit/${this.props.id}`;
            }}>
              {this.state.edit.draft ? 'Click Here to Edit or Submit your Draft' : 'Click Here to Edit your Submission'}
            </Button>
          </div>
          <div>
            You can add or make changes to your submission until it is marked as "Received". After that, any further changes must be submitted as an additional edit request.
        </div>
        </div>
      )}
    </Wrapper>;
  }
}
